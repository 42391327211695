import { useContext, useEffect, useState } from 'react'
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonToast } from '@ionic/react'
import { ellipsisVertical, refresh } from 'ionicons/icons'
import CommonHeader from '../../commonComponents/CommonHeader'
import { getFormattedDate, limitDecimalPlaces } from '../../utils'
import axios from 'axios'
import { AuthContext } from '../../auth'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import ConfirmationModal from '../../commonComponents/ConfirmationModal'
import CancelAppointmentForm from './CancelAppointmentForm'
import { AccessControl } from '../../AccessControl'
import StandardContainer from '../../commonComponents/StandardContainer'
import { fontSizes } from '../../theme/typography'

let styles = {
  ionListItem: {
    marginTop: 4,
    marginBottom: 4,
  },
}

function ActionMenu({ appointment, payedExternal, status, updateStatus, userData, cancelAppointment, getAppointment }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [historyModalOpen, setHistoryModalOpen] = useState(false) // State for history modal
  const [defaultChargeEnabled, setDefaultChargeEnabled] = useState(false)
  const [customChargeValue, setCustomChargeValue] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [cardOnFile, setCardOnFile] = useState(false)
  const { locationData, locationId, getBusinessData } = useContext(AuthContext)
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const isCustomerSubscribed = appointment?.customChargeValue?.isSubscribed
  const locationCancelationSettings = isCustomerSubscribed
    ? selectedLocationData?.appointmentSettings?.cancellationSettings?.subscriber
    : selectedLocationData?.appointmentSettings?.cancellationSettings?.nonSubscriber
  const hoursBefore = locationCancelationSettings?.hoursBefore || 24
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)

  const cancellationAllowed = timeDifferenceInHours > hoursBefore

  const handleCustomChargeValue = (value) => {
    setCustomChargeValue(value)
    if (value > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
  }
  const getCancellationCharges = () => {
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    if (locationCancelationSettings?.type === 'fixed') {
      defaultCancellationCharges = locationCancelationSettings?.value
    } else if (locationCancelationSettings?.type === 'percentage') {
      const percentage = locationCancelationSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const handleCancelAppointment = async () => {
    setLoading(true)
    let cancelationCharge = 0
    let customizedCancelationCharge = !defaultChargeEnabled

    if (locationCancelationSettings?.charge && cardOnFile) {
      cancelationCharge = cancellationAllowed ? 0 : parseFloat(defaultChargeEnabled ? defaultCancellationCharges : customChargeValue)
    } else {
      cancelationCharge = 0
      customizedCancelationCharge = false
    }

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'Canceled',
        cancelledBy: 'Stylist',
        paidExternal: true,
        cancelationCharge,
        customizedCancelationCharge,
      }

      await axios.patch(`/appointment_v2/updateStatus?id=${appointment?._id}`, input)

      const paymentObjectWithCancelationCharge = {
        ...appointment?.payment,
        cancelationCharge,
      }

      await axios.patch(`/appointment_v2?id=${appointment?._id}`, {
        payment: paymentObjectWithCancelationCharge,
      })
      setToast({ isOpen: true, message: 'Appointment canceled successfully.', color: 'success' })
      await getAppointment()
      closeModal()
    } catch (error) {
      setToast({ isOpen: true, message: 'Error canceling appointment. Please try again later.', color: 'danger' })
    }

    setLoading(false)
  }
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customChargeValue, 2)
    setCustomChargeValue(formattedValue)
  }, [customChargeValue])

  const openModal = () => {
    setModalOpen(true)
    getBusinessData()
  }

  const openHistoryModal = () => {
    setHistoryModalOpen(true)
  }

  const closeHistoryModal = () => {
    setHistoryModalOpen(false)
  }

  const getCard = async () => {
    try {
      let response = await axios.get(`/customer_v2/cardStripe?customerId=${appointment?.customer?._id}`)
      setCardOnFile(response?.data?.cardOnFile || false)
    } catch (error) {
      console.error('Error fetching card information:', error)
    }
  }
  useEffect(() => {
    getCard()
  }, [])

  const closeModal = () => {
    getBusinessData()
    setDefaultChargeEnabled(false)
    setCustomChargeValue(false)
    setError(false)
    setLoading(false)
    setModalOpen(false)
  }
  const toggleDefaultCharge = () => {
    setDefaultChargeEnabled(!defaultChargeEnabled)
    setCustomChargeValue('')
    setError('')
  }
  let appointmentTimeStamps = appointment?.timing?.stateTimestamps

  return (
    <>
      <IonButtons slot='end' style={{ color: 'black' }}>
        <>
          <IonButton id='popover-button' fill='clear'>
            <IonIcon icon={ellipsisVertical} />
          </IonButton>
          <IonPopover trigger='popover-button' dismissOnSelect={true}>
            <IonContent>
              <IonList>
                <AccessControl componentName={'appointment_history'}>
                  <IonItem button={true} detail={false} onClick={openHistoryModal}>
                    <IonIcon icon={refresh} style={{ marginRight: 10 }} /> History
                  </IonItem>
                </AccessControl>
                {['In Progress'].includes(status) ? (
                  <IonItem button={true} detail={false} onClick={payedExternal} style={styles.ionListItem}>
                    Paid External - Complete
                  </IonItem>
                ) : null}

                {['Complete', 'Scheduled'].includes(status) ? null : (
                  <IonItem
                    button={true}
                    detail={false}
                    onClick={() => {
                      updateStatus('Scheduled')
                    }}
                    style={styles.ionListItem}
                  >
                    Change Status Back to "Scheduled"
                  </IonItem>
                )}
                {!['Complete'].includes(status) && status !== 'Canceled' ? (
                  <>
                    <AccessControl componentName={'cancel_appointment'}>
                      <IonItem
                        button={true}
                        detail={false}
                        onClick={() => {
                          openModal()
                        }}
                      >
                        Cancel Appointment
                      </IonItem>
                    </AccessControl>
                  </>
                ) : null}
              </IonList>
            </IonContent>
          </IonPopover>
        </>
        <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
          <CommonHeader title={'Cancel Appointment'} />
          {cancellationAllowed || !cardOnFile || !locationCancelationSettings?.charge ? (
            <ConfirmationModal
              message={'Are you sure you want to cancel this appointment'}
              cancel={() => closeModal()}
              loading={loading}
              save={() => handleCancelAppointment()}
              cancelText={'Dismiss'}
              saveText={'Cancel Appointment'}
            />
          ) : (
            <CancelAppointmentForm
              locationCancelationSettings={locationCancelationSettings}
              defaultCancellationCharges={defaultCancellationCharges}
              defaultChargeEnabled={defaultChargeEnabled}
              toggleDefaultCharge={toggleDefaultCharge}
              appointment={appointment}
              customChargeValue={customChargeValue}
              handleCustomChargeValue={handleCustomChargeValue}
              error={error}
              closeModal={closeModal}
              loading={loading}
              handleCancelAppointment={handleCancelAppointment}
            />
          )}
        </IonModal>
        <IonModal isOpen={historyModalOpen} onDidDismiss={closeHistoryModal}>
          <CommonHeader title={'History'} closeButton={true} closeModal={closeHistoryModal} />
          {}
          {/* Add your history content here */}
          <IonContent>
            <StandardContainer padding={20}>
              {appointmentTimeStamps && (
                <>
                  <div style={{ fontFamily: 'FigTree' }}>
                    {appointmentTimeStamps?.scheduled && (
                      <div style={{ marginTop: 10 }}>
                        <IonLabel style={{ fontSize: fontSizes.size18 }}>Scheduled : {getFormattedDate(appointmentTimeStamps?.scheduled)}</IonLabel>
                      </div>
                    )}
                    {appointmentTimeStamps?.arrived && (
                      <div style={{ marginTop: 10 }}>
                        <IonLabel style={{ fontSize: fontSizes.size18 }}>Arrived : {getFormattedDate(appointmentTimeStamps?.arrived)}</IonLabel>
                      </div>
                    )}
                    {appointmentTimeStamps?.inProgress && (
                      <div style={{ marginTop: 10 }}>
                        <IonLabel style={{ fontSize: fontSizes.size18 }}>In Progress : {getFormattedDate(appointmentTimeStamps?.inProgress)}</IonLabel>
                      </div>
                    )}
                    {appointmentTimeStamps?.complete && (
                      <div style={{ marginTop: 10 }}>
                        <IonLabel style={{ fontSize: fontSizes.size18 }}>Complete : {getFormattedDate(appointmentTimeStamps?.complete)}</IonLabel>
                      </div>
                    )}
                  </div>
                </>
              )}
            </StandardContainer>
          </IonContent>
        </IonModal>
      </IonButtons>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
    </>
  )
}

export default ActionMenu
